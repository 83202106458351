.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.btn_login_signup {
	/* background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5); */
	background: linear-gradient(-45deg, #FFA63D, #DAA520, #139c76, #CFB53B);
  background-size: 400%;
  font-size: 1em;
  z-index: 1;
  animation: animate 16s linear infinite;
  color:white
}

.send_inquiry_btn:hover {
  background-color: green;
	
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

#twitter,#facebook,#linkedin,#instagram {
  background: linear-gradient(-45deg, #FFA63D, #FF3D77, #139c76, #3CF0C5);
  border-radius: 10px;
}

.bg_color{
  background: linear-gradient(-45deg, #ebd197, #b48811, #a2790d, #bb9b49);
}


.footer_bg_color{
  background: linear-gradient(-45deg, #ebd197, #b48811, #a2790d, #bb9b49);
}



:root {
	--d: 3500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	/* --c1: rgb(25 118 210); */
  --c1:#DAA520;
	--c2: rgba(168, 239, 255, 0.1);
  --mainColor: #29335c;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #eee;
}



@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

.box {
	font-size: 4vw;
	/* margin: max(1rem, 3vw); */
	border-bottom: 0.35rem solid;
	padding: 2vw;
  border-radius: 10px;
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards;
}


@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}

@keyframes borderRadial {
	20% {
		--gradX: 100%;
		--gradY: 50%;
	}
	40% {
		--gradX: 100%;
		--gradY: 100%;
	}
	60% {
		--gradX: 50%;
		--gradY: 100%;
	}
	80% {
		--gradX: 0%;
		--gradY: 50%;
	}
	100% {
		--gradX: 50%;
		--gradY: 0%;
	}
}

.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  /* transition: transform .5s ease-in-out, margin-bottom 2s ease-in-out;
  transition-delay: calc(.1s *var(--i));
  transform: scale(1) translateY(0); */

}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}

.nav-btn {
	padding-right: 5px;
	cursor: pointer;
  /* position: relative; */
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden; 
	 opacity: 0;
	font-size: 1.8rem;
}

@media only screen and (max-width: 1000px) {
	.nav-btn {
		visibility: visible;
		opacity: 1;
	}

	.navbarNav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 50%;
    margin-left: 50%;
		display: flex;
		/* flex-direction: column; */
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
    z-index: 40
	}

	.responsive_nav {
		transform: translateY(100vh);
	}

	.nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

  

}


.img_slider {
  width: 100%;
  height: 100%;

  /* background-image: url('./slider_images/business_con1.jpg'); */
  background-size: 100% 100%;
  animation: changeImage 40s linear infinite;
}

@keyframes changeImage {
  0%{
    background-image: url('./slider_images/business_con1.jpg');
  }
  25%{
    background-image: url('./slider_images/business_con2.jpg');
  }
  50%{
    background-image: url('./slider_images/business_con3.jpg');
  }
  75%{
    background-image: url('./slider_images/business_con1.jpg');
  }
  100%{
    background-image: url('./slider_images/business_con2.jpg');
  }
}

.card {
  display: flex;
  align-items: flex-start;
    justify-content: center;
}

.card {
  background: #fff;
  /* padding:10px; */
  width:21em;
  height: 25em;
  border-radius: 1em;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding:10px;
  /* margin-top:15px; */
}

.card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  font-family: 'poppins';

}

.card p {
  color: #777;
  font-family: 'poppins';
}

.card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.card-body {
  padding: 1.2em;
}

/* Categories Card */

/* .cat_card {
  display: flex;
  align-items: flex-start;
    justify-content: center;
} */

.cat_card {
  background: #fff;
  /* padding:10px; */
  width: 15em;
  height: 18.5em;
  border-radius: 1em;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.cat_card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.cat_card img {
  width: 100%;
  height: 50%;
  object-fit: cover;
  padding:10px,
}

.cat_card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  font-family: 'poppins';

}

.cat_card p {
  color: #777;
  font-family: 'poppins';
}

.cat_card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.cat_card-body {
  padding: 1.2em;
}

/* subcat_Card */

.subcat_card {
  background: #fff;
  /* padding:10px; */
  width: 15em;
  height: 15em;
  border-radius: 1em;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.subcat_card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.subcat_card img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  padding:10px,
}

.subcat_card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  font-family: 'poppins';

}

.subcat_card p {
  color: #777;
  font-family: 'poppins';
}

.subcat_card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.subcat_card-body {
  padding: 1.2em;
}



/* animated  */

.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down{
  0%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(-10px);
  }
}
@keyframes up-down{
  0%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(-10px);
  }
}